.Footer{
    position: relative;
}
.Footer > hr {
    border: 1px solid var(--lightgray);
}
.footers{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.footer-social{
    display: flex;
    gap: 4rem;
}
.social-image{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}
.footer-logo > img{
    width: 10rem;
}
.blur-footer-1{
    bottom:0;
    right:15%;
    width: 26rem;
    height: 12rem;
    filter: blur(220px);
    background: red;
}
.blur-footer-2{
    bottom:0;
    left:15%;
    width: 26rem;
    height: 12rem;
    filter: blur(150px);
}
@media screen and (max-width: 768px) {
    .Footer{
        transform: scale(0.75);
    }
}