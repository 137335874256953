.Plans{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans-header{
    gap:2rem;
    text-transform: uppercase;
    display: flex;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plans > :nth-child(2) > svg{
    fill: white;
}
.plans > :nth-child(2) > button{
    color: var(--orange);
}
.plan-data{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color:white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan-data:nth-child(2){
    transform: scale(1.1);
    background-color: var(--orange);
}
.plan-data > svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan-data > :nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan-data > :nth-child(3){
    font-size: 2rem;
    font-weight: bold;
}
.plan-data > :nth-child(5){
    font-size: 0.75rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    gap:1rem;
    align-items: center;
}
.feature > img{
    width: 1rem;
}
.blur-plan-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.blur-plan-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}
@media screen and (max-width: 768px) {
    .plans-header,
    .plans{
        flex-direction: column;
    }
    plans > :nth-child(2){
        transform: none;
    }

}