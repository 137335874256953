.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j > hr{
    position: absolute;
    width: 13.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin-top: -10px ;
}
.left-j > div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding:  1rem 2rem;
}
.email-container > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(-- --lightgray);
    text-align: center;

}
::placeholder{
    color: var(--lightgray);
}
.join-btn{
    background-color: var(--orange);
    color: white;  
}
@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
    .email-container{
        padding: 0rem;
        border-radius: 5px;
        height: 3rem;
        transform: scale(0.9);
    }
    .join-btn{
        border-radius: 5px;
    }
  
}